import ApiService, { insertParams } from "@/util/api.service";

const ENDPOINTS = {
  CASH_WITHDRAWAL: "cash_withdrawals",
  PRINT_CASH_WITHDRAWAL_RECEIPT: "cash_withdrawals/{id}/print/receipt"
};

export class DriverService extends ApiService {
  static getCashWithdrawal = password => {
    return this.get(ENDPOINTS.CASH_WITHDRAWAL, { password });
  };

  static postCashWithdrawal = passwordAndAmount => {
    return this.post(ENDPOINTS.CASH_WITHDRAWAL, { ...passwordAndAmount });
  };

  static printCashWithdrawalReceipt = id => {
    return this.post(
      insertParams(ENDPOINTS.PRINT_CASH_WITHDRAWAL_RECEIPT, { id })
    );
  };
}

export default DriverService;
