export const SERVICE_TYPES = {
  DELIVERY: "delivery",
  RECEIVE: "receive",
  C2C_CONSIGN: "c2c_consign",
  RETURN_PACKETS_FOR_CLIENT: "return_packets_for_client",
  RECLAMATION_ASSISTANT: "reclamation_assistant",
  CASH_WITHDRAW: "cash_withdraw"
};

export const SERVICE_SUB_TYPES = {
  RETURN_PACKET_CONSIGN: "return_packet_consign",
  B2C_CONSIGN: "b2c",
  EXISTING_CLAIM_CONSIGN: "existing_claim_consign"
};

export const PACKET_TYPES = {
  MK2C: "mk2c"
};

export default SERVICE_TYPES;
