import { DriverService } from "@/util/driver.service";
import { useAction } from "@/util/store-helper";

// STATE

const getDefaultState = () => {
  return {
    cashWithdrawal: null
  };
};

const state = getDefaultState();

// GETTERS

const getters = {};

// ACTIONS

const actions = {
  async getCashWithdrawal({ commit, dispatch }, password) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await DriverService.getCashWithdrawal(password);

      commit("setCashWithdrawal", response.data);

      return response;
    });
  },

  async withdrawCash({ commit, dispatch }, passwordAndAmount) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await DriverService.postCashWithdrawal(
        passwordAndAmount
      );

      commit("setCashWithdrawal", response.data);

      return response;
    });
  },

  async printCashWithdrawalReceipt({ commit, dispatch }, id) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await DriverService.printCashWithdrawalReceipt(id);
    });
  }
};

// MUTATIONS

const mutations = {
  setCashWithdrawal(state, cashWithdrawal) {
    state.cashWithdrawal = cashWithdrawal;
  },

  resetDriverState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
